.pageHeader {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Heebo', sans-serif;
    line-height: 21px;

    @media (max-width: 559px) {
      flex-direction: column;
    }
  }

  .pageHeader-action {
    display: flex;

    @media (max-width: 559px) {
      width: 100%;
      justify-content: flex-end;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    color: #153243;
  }
  .subtitle {
    color: #73848e;
    font-size: 14px;
    margin: 32px 0;
    @media (max-width: 600px) {
      margin: 8px;
    }
  }
}
